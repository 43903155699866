import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { clinicState } from '../../../../globalState'
import Loader from '../../../Loader/Loader'
import PersonalInfo from '../../../FormCompoent/PersonalInfo/PersonalInfo'
import JotForm from '../../../FormCompoent/JotForm/JotForm'
import WelcomePage from '../../../FormCompoent/WelcomePage/WelcomePage'
import PatientService from '../../../../services/PatientService'
import { Modal } from 'antd'
import generate from '../../../../utilities/generatePatientMatchingErrorModalContent'
import { LunaJoyFoundationService } from '../../../../services/LunajoyFoundationService'
import { formatDate } from '../../../../utilities/dateFormat'
import jotFormIds from '../../../../data/jot-form/form-ids-mapping'
import JotFormService from '../../../../services/JotFormService'

const LunajoyObgynScreening = () => {
  const { clinic } = useRecoilValue(clinicState)
  const [loading, setLoading] = useState(false)
  const formId = jotFormIds['epds-anxiety']
  const formIdTemp = jotFormIds['epds-anxiety-temp']
  const [currentPage, setCurrentPage] = useState(null)
  const [jotFormLink, setJotFormLink] = useState(null);
  const [personalDetails, setPersonalDetails] = useState(null)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const contact_id = queryParams.get('contactId');
  const email = queryParams.get('email')

  useEffect(() => {
    if (contact_id) {
      setCurrentPage('personal-information')
      // loadPersonalInfo();

    }
    if (email) {
      setCurrentPage('personal-information')
      setPersonalDetails({ email: email })
    }
  }, [contact_id, email])

  const loadPersonalInfo = async () => {
    try {
      setLoading(true);

      const ghlContact = await LunaJoyFoundationService.getPatientWithContactId(contact_id)
      const phone = ghlContact.phone_no
      ghlContact['phone_no'] = phone.slice(2);
      setPersonalDetails(ghlContact)
      console.log(ghlContact)

      setLoading(false);
    } catch (error) {
      console.error("Not found patient in ghl")
    }
  }

  const handlePersonalInfoSubmit = async (values) => {
    window.rudderanalytics.page();
    try {
      setLoading(true)

      const result = await PatientService.findOrCreatePatient({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email || email,
        phone: values.phone,
        dob: formatDate(values.dob)
      })
      console.log(result)
      if (result?.findOrCreatePatient?.mismatch) {
        const errorReason = generate(result?.findOrCreatePatient?.mismatch)

        const formLink = JotFormService.generateJotFormLink(formIdTemp, {
          clinicName: clinic.name,
          firstName: values.firstName,
          lastName: values.lastName,
          dateOf: formatDate(values.dob),
          phone: values.phone,
          email: values.email || email,
          tags: clinic.tags,
          clinicCode: clinic.clinicCode,
          screening: 'EPDS',
          isExisting: !!contact_id,
          pregnancyStatus: values.pregnancyStatus,
          contactId: result?.findOrCreatePatient?.contactId,
          isTemporary: "true",
          mismatchReason: errorReason,
          clinicSpecialty: clinic.clinicSpecialty
        })
        setJotFormLink(formLink)
        setCurrentPage('jot-form')

        // const { title, content, helpMessage } = generate(result?.findOrCreatePatient?.mismatch)
        // Modal.error({
        //   title,
        //   content: <>{content}<br /><p>{helpMessage}</p></>,
        //   okText: 'OK',
        //   centered: true,
        //   okButtonProps: { style: { width: 60 } }
        // })
      } else if (result?.findOrCreatePatient?.contactId) {
        const formLink = JotFormService.generateJotFormLink(formId, {
          clinicName: clinic.name,
          firstName: values.firstName,
          lastName: values.lastName,
          dateOf: formatDate(values.dob),
          phone: values.phone,
          email: values.email || email,
          tags: clinic.tags,
          clinicCode: clinic.clinicCode,
          screening: 'EPDS',
          isExisting: !!contact_id,
          pregnancyStatus: values.pregnancyStatus,
          contactId: result?.findOrCreatePatient?.contactId,
          isTemporary: "false",
          mismatchReason: "Not Applicable",
          clinicSpecialty: clinic.clinicSpecialty
        })
        setJotFormLink(formLink)
        setCurrentPage('jot-form')
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  };
  if (!clinic) return <Loader />;

  return !currentPage
    ? <WelcomePage setCurrentPage={setCurrentPage} />
    : currentPage === 'personal-information'
      ? <PersonalInfo handleSubmit={handlePersonalInfoSubmit} types={"EPDS"} personalDetails={personalDetails} loading={loading} setCurrentPage={setCurrentPage} />
      : <JotForm JotFormLink={jotFormLink} formId={formId} clinicDetails={clinic} />
}

export default LunajoyObgynScreening