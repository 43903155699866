import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import { clinicState } from '../../globalState';
import ObgynScreening from '../../components/screenings/obgynScreening/ObgynScreening'
import styles from './Screening.module.scss';
import Loader from '../../components/Loader/Loader';
import GdsScreening from '../../components/screenings/gdsScreening/GdsScreening';
import Phq9Gad7Screening from '../../components/screenings/phq9Gad7Screening/Phq9Gad7Screening';
import LunajoyObgynScreening from '../../components/screenings/lunajoyScreening/lunajoyObgynScreening/LunajoyObgynScreening';
import LunajoyPhq9Gad7Screening from '../../components/screenings/lunajoyScreening/lunajoyPhq9Gad7Screening/LunajoyPhq9Gad7Screening';
import { LunaJoyFoundationService } from '../../services/LunajoyFoundationService'
import generateHeadText from '../../utilities/generateHeadText';
import Background from '../../components/Background/Background';
import EMRDirectScreening from '../../components/screenings/EMRScreening/EMRDirectScreening';
import { useTranslation } from 'react-i18next';

const Screening = () => {
  const { slug, direct } = useParams()
  const [{ clinic, error }, setClinic] = useRecoilState(clinicState)

  // multilingual text
  const { t } = useTranslation()
  const errorTitle = t('errorTitle')

  const loadClinic = useCallback(async () => {
    try {
      const currentSlug = slug

      const clinicInfo = await LunaJoyFoundationService.getClinicBySlug(slug)
      const clinicSpecialty = clinicInfo?.clinicGHLTags?.find(tag => tag.type === 'Specialty')?.value?.trim() || ''

      let matchingScreening = {}
      if (direct) {
        matchingScreening['type'] = direct;
      } else {
        for (const [type, slug] of Object.entries(clinicInfo.screeningSlugs)) {
          if (slug === currentSlug) {
            matchingScreening['type'] = type;
            matchingScreening['screeningName'] = slug;
          }
        }

      }
      const clinic = { ...clinicInfo, matchedScreening: matchingScreening, clinicSpecialty }
      setClinic({ clinic, error: null })
      document.title = generateHeadText(clinic).title
    } catch (error) {
      console.log(error)
      setClinic({ clinic: null, error: 'Not Found' })
    }
  }, [slug])

  useEffect(() => {
    if (slug) {
      loadClinic()
    }
  }, [slug])

  if (error || !slug) return (
    <main>
      <Background clinicDetails={null}>
        <div className={styles.container}>
          <h1 className={styles.screeningHeader}>{errorTitle}</h1>
        </div></Background>
    </main>
  )

  if (!clinic) return (
    <main>
      <Background clinicDetails={null}>
        <div className={styles.container}>
          <h1 className={styles.screeningHeader}><Loader /></h1>
        </div>
      </Background>
    </main>
  )

  return (
    <main>

      {
        clinic.name === 'Lunajoy' && clinic.matchedScreening.type === 'obgyn'
          ? <LunajoyObgynScreening />
          : clinic.name === 'Lunajoy' && clinic.matchedScreening.type === 'phq9-gad7'
            ? <LunajoyPhq9Gad7Screening />
            : clinic.matchedScreening.type === 'obgyn'
              ? <ObgynScreening />
              : clinic.matchedScreening.type === 'gds'
                ? <GdsScreening />
                : clinic.matchedScreening.type === 'phq9-gad7'
                  ? <Phq9Gad7Screening />
                  : clinic.matchedScreening.type === 'direct'
                    ? <EMRDirectScreening screeningType='phq9-gad7' />
                    : <h1 className={styles.screeningHeader}>Sorry, this screening is not available</h1>
      }

    </main>
  )
}

export default Screening